<script lang="ts" setup>
import { inputNumberProps } from './input-number'
import { useInputNumber } from './use-input-number'

const props = defineProps(inputNumberProps)
const { fieldValue, onDecrease, onIncrease } = useInputNumber(props)
</script>

<template>
  <div class="input-number">
    <button class="input-number__decrease" @click.prevent="onDecrease">
      <svg
        width="10"
        height="2"
        viewBox="0 0 10 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55228 0 10 0.447715 10 1C10 1.55228 9.55228 2 9 2H1C0.447716 2 0 1.55228 0 1Z"
        />
      </svg>
    </button>

    <button class="input-number__increase" @click.prevent="onIncrease">
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 5C0 4.44772 0.447715 4 1 4H9C9.55228 4 10 4.44772 10 5C10 5.55228 9.55228 6 9 6H1C0.447716 6 0 5.55228 0 5Z"
        />
        <path
          d="M5 0C5.55228 0 6 0.447715 6 1V9C6 9.55228 5.55228 10 5 10C4.44772 10 4 9.55228 4 9V1C4 0.447716 4.44772 0 5 0Z"
        />
      </svg>
    </button>

    <div class="input-number__value" v-text="fieldValue"></div>
  </div>
</template>

<style lang="scss">
.input-number {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  min-width: 115px;
  height: 32px;

  &__decrease,
  &__increase {
    position: absolute;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    border: 2px solid transparent;
    border-radius: 50%;
    background: var(--c-grayscale-input-background);

    svg {
      fill: #14162b;
    }

    &:hover {
      border-color: var(--c-primary-default);

      svg {
        fill: var(--c-primary-default);
      }
    }
  }

  &__decrease {
    left: 0;
  }

  &__increase {
    right: 0;
  }

  &__value {
    cursor: default;

    color: var(--c-grayscale-white);
  }
}
</style>
