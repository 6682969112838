<script lang="ts" setup>
import { textareaProps } from './textarea'
import { useTextarea } from './use-textarea'

const props = defineProps(textareaProps)
const { id, fieldValue, isFocused, onFocus, onFocusOut } = useTextarea(props)
const { name, label, rows, disabled, caption, error } = toRefs(props)
</script>

<template>
  <div
    class="textarea"
    :class="{
      'textarea--focused': isFocused,
      'textarea--disabled': disabled,
      'textarea--error': error,
    }"
  >
    <div class="textarea__body">
      <label :for="id" class="textarea__label">{{ label }}</label>

      <textarea
        :id="id"
        class="textarea__field"
        v-model="fieldValue"
        :name="name"
        :rows="rows"
        :disabled="disabled"
        @focus="onFocus"
        @focusout="onFocusOut"
      ></textarea>
    </div>

    <div class="textarea__caption">
      <span v-if="error" class="textarea__caption-error">{{ error }}</span>

      <span v-else>{{ caption }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.textarea {
  &--focused &__body {
    background: var(--c-grayscale-off-white);
  }

  &--disabled,
  &--disabled &__label {
    cursor: not-allowed;
  }

  &--disabled &__field {
    resize: none;
  }

  &--disabled &__body {
    background: var(--c-grayscale-input-background);
  }

  &--error &__body {
    border-color: var(--c-error-default);
    background: #ffebee;
  }

  &--error &__label {
    color: var(--c-error-dark);
  }

  &__body {
    display: flex;
    flex-direction: column;

    border: 2px solid transparent;
    border-radius: 16px;
    background: var(--c-grayscale-placeholder);
  }

  &__label {
    padding: 24px 24px 0;

    color: var(--c-grayscale-label);

    @include t-desktop-text-x-small;
  }

  &__field {
    margin: 0 5px 5px 0;
    padding: 0 24px 24px;

    color: #14162b;
    border-radius: 16px;
    background: transparent;

    @include t-desktop-text-small;

    &:focus,
    &:focus-within {
      outline: none;
      background: var(--c-grayscale-off-white);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__field:focus + &__body {
    background: var(--c-grayscale-off-white);
  }

  &__caption {
    margin-top: 10px;

    color: var(--c-grayscale-label);

    @include t-desktop-text-x-small;

    &-error {
      color: var(--c-error-dark);
    }
  }
}

</style>
