import type { TTextareaProps } from './textarea'

export const useTextarea = (props: TTextareaProps) => {
  const customId = useId()
  const id = props.id || customId
  const { value: fieldValue } = useField(props.name, undefined, {
    initialValue: '',
  })
  const isFocused = ref(false)

  const onFocus = () => {
    if (props.disabled || props.error) return

    isFocused.value = true
  }

  const onFocusOut = () => {
    isFocused.value = false
  }

  return {
    id,
    fieldValue,
    isFocused,
    onFocus,
    onFocusOut,
  }
}
