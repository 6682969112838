import type { ExtractPropTypes } from 'vue'

export const inputNumberProps = {
  name: {
    type: String,
    required: true,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
} as const

export type TInputNumberProps = ExtractPropTypes<typeof inputNumberProps>
