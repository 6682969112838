<script lang="ts" setup>
import { modalProps, modalEmits } from './modal'
import { useModal } from './use-modal'

import CloseIcon from '@components/ui/icons/close.vue'

const props = defineProps(modalProps)
const emits = defineEmits(modalEmits)
const { modalRef, overlayRef, isOpenedModal, onCloseModal } = useModal(
  props,
  emits
)
const { showClose } = toRefs(props)
</script>

<template>
  <ClientOnly>
    <Teleport to="body">
      <div v-if="isOpenedModal" ref="modalRef" class="modal">
        <div ref="overlayRef" class="modal__overlay">
          <div class="modal__content">
            <div v-if="showClose" class="modal__close">
              <slot name="modal-close">
                <button @click.prevent="onCloseModal"><CloseIcon /></button>
              </slot>
            </div>

            <slot />
          </div>
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<style lang="scss">
.modal {
  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background: rgb(45, 45, 45, 0.7);
  }

  &__content {
    position: relative;

    display: flex;
    overflow-y: scroll;

    width: 100%;
    max-width: 600px;
    padding: 96px 128px;

    border-radius: 24px;
    background: var(--c-grayscale-white);
  }

  &__close {
    position: absolute;
    top: 32px;
    right: 32px;
  }
}
</style>
