import type { CSSProperties } from 'vue'
import type { TSliderProps } from './slider'

export const useSlider = (props: TSliderProps) => {
  const sliderRef = shallowRef()
  const sliderButtonRef = ref()
  const showTooltip = ref(false)

  const initData = reactive({
    sliderSize: 1,
    startX: 0,
    newPosition: 0,
    startPosition: 0,
    isClick: false,
    hovering: false,
    dragging: false,
  })

  const { value: fieldValue, setValue } = useField(props.name, undefined, {
    initialValue: 0,
  })

  const markedValue = computed(
    () => `${fieldValue.value}${props.marker ? props.marker : ''}`
  )

  const precision = computed(() => {
    const precisions = [props.min, props.max, props.step].map((item) => {
      const decimal = `${item}`.split('.')[1]

      return decimal ? decimal.length : 0
    })

    return Math.max.apply(null, precisions)
  })

  const setFieldValue = (newValue: number) => {
    if (newValue === null || Number.isNaN(+newValue)) return

    if (newValue < 0) {
      newValue = 0
    } else if (newValue > 100) {
      newValue = 100
    }

    const lengthPerStep = 100 / ((props.max - props.min) / props.step)
    const steps = Math.round(newValue / lengthPerStep)
    let val = steps * lengthPerStep * (props.max - props.min) * 0.01 + props.min

    val = Number.parseFloat(val.toFixed(precision.value))

    setValue(val ?? props.min)
  }

  const buttonPosition = computed(() => {
    return `${
      (100 * (fieldValue.value - props.min)) / (props.max - props.min)
    }%`
  })

  const buttonStyle = computed<CSSProperties>(() => {
    return { left: buttonPosition.value }
  })

  const barSize = computed(() => {
    return `${
      (100 * (fieldValue.value - props.min)) / (props.max - props.min)
    }%`
  })

  const barStyle = computed<CSSProperties>(() => {
    return {
      width: barSize.value,
      left: '0%',
    }
  })

  const resetSize = () => {
    if (sliderRef.value) {
      initData.sliderSize = sliderRef.value['clientWidth']
    }
  }

  const setPosition = (percent: number) => {
    setFieldValue(percent)
  }

  const onSliderDown = async (event: MouseEvent | TouchEvent) => {
    if (props.disabled) return

    resetSize()

    let newPercent = 0

    const clientX =
      (event as TouchEvent).touches?.item(0)?.clientX ??
      (event as MouseEvent).clientX

    const sliderOffsetLeft = sliderRef.value!.getBoundingClientRect().left

    newPercent = ((clientX - sliderOffsetLeft) / initData.sliderSize) * 100

    if (newPercent < 0 || newPercent > 100) return

    setPosition(newPercent)
  }

  const getClientXY = (event: MouseEvent | TouchEvent) => {
    let clientX: number
    let clientY: number
    if (event.type.startsWith('touch')) {
      clientY = (event as TouchEvent).touches[0].clientY
      clientX = (event as TouchEvent).touches[0].clientX
    } else {
      clientY = (event as MouseEvent).clientY
      clientX = (event as MouseEvent).clientX
    }
    return {
      clientX,
      clientY,
    }
  }

  const handleMouseEnter = () => {
    initData.hovering = true

    showTooltip.value = true
  }

  const handleMouseLeave = () => {
    initData.hovering = false

    showTooltip.value = false
  }

  const onDragStart = (event: MouseEvent | TouchEvent) => {
    initData.dragging = true
    initData.isClick = true

    const { clientX } = getClientXY(event)

    initData.startX = clientX
    initData.startPosition = Number.parseFloat(buttonPosition.value)
    initData.newPosition = initData.startPosition
  }

  const onDragging = (event: MouseEvent | TouchEvent) => {
    if (initData.dragging) {
      initData.isClick = false
      showTooltip.value = true

      resetSize()

      let diff: number
      const { clientX } = getClientXY(event)

      diff = ((clientX - initData.startX) / initData.sliderSize) * 100

      const newPosition = initData.startPosition + diff

      setPosition(newPosition)
    }
  }

  const onDragEnd = () => {
    if (initData.dragging) {
      showTooltip.value = false

      window.removeEventListener('mousemove', onDragging)
      window.removeEventListener('touchmove', onDragging)
      window.removeEventListener('mouseup', onDragEnd)
      window.removeEventListener('touchend', onDragEnd)
      window.removeEventListener('contextmenu', onDragEnd)
    }
  }

  const onButtonDown = (event: MouseEvent | TouchEvent) => {
    if (props.disabled) return

    event.preventDefault()

    onDragStart(event)

    window.addEventListener('mousemove', onDragging)
    window.addEventListener('touchmove', onDragging)
    window.addEventListener('mouseup', onDragEnd)
    window.addEventListener('touchend', onDragEnd)
    window.addEventListener('contextmenu', onDragEnd)

    sliderButtonRef.value!.focus()
  }

  return {
    sliderRef,
    sliderButtonRef,
    showTooltip,
    markedValue,
    buttonStyle,
    barStyle,
    onSliderDown,
    handleMouseEnter,
    handleMouseLeave,
    onButtonDown,
  }
}
