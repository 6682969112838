import type { ExtractPropTypes } from 'vue'

export const textareaProps = {
  id: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  rows: {
    type: Number,
    default: 3,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  caption: {
    type: String,
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
} as const

export type TTextareaProps = ExtractPropTypes<typeof textareaProps>
