import type { ExtractPropTypes } from 'vue'

export const modalProps = {
  opened: {
    type: Boolean,
    default: false,
  },
  showClose: {
    type: Boolean,
    default: true,
  },
} as const

export type TModalProps = ExtractPropTypes<typeof modalProps>

export const modalEmits = {
  'on-close': (value: boolean) => typeof value === 'boolean',
}

export type TModalEmits = typeof modalEmits
