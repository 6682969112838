import type { SetupContext } from 'vue'
import type { TModalEmits, TModalProps } from './modal'

export const useModal = (
  props: TModalProps,
  emit: SetupContext<TModalEmits>['emit']
) => {
  const modalRef = ref(undefined)
  const overlayRef = ref(undefined)

  const isOpenedModal = computed({
    get() {
      return props.opened
    },
    set(value) {
      emit('on-close', value)
    },
  })

  function onCloseModal() {
    isOpenedModal.value = false
  }

  return {
    modalRef,
    overlayRef,
    isOpenedModal,
    onCloseModal,
  }
}
