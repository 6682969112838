<script setup lang="ts">
import Button from '@components/ui/button/index.vue'
import Toast from '@components/ui/toast'
import Radio from '@components/ui/radio/index.vue'
import Modal from '@components/ui/modal/index.vue'
import Slider from '@components/ui/slider/index.vue'
import InputNumber from '@components/ui/input-number/index.vue'
import Textarea from '@components/ui/textarea/index.vue'

function onToastGeneral() {
  Toast({ type: 'general', message: 'toast general', showClose: true })
}
function onToastSuccess() {
  Toast({ type: 'success', message: 'toast success', showClose: true })
}
function onToastError() {
  Toast({ type: 'error', message: 'toast error', showClose: true })
}

const { values } = useForm({ initialValues: { radio: '2222' } })

watch(values, () => console.log(values), { deep: true })

const isOpenedModal = ref(false)
function onOpenModal() {
  isOpenedModal.value = true
}
function onCloseModal(value: boolean) {
  isOpenedModal.value = value
}
</script>

<template>
  <div>
    <Textarea name="textarea" label="Textarea" />
  </div>
  <div>
    <Button type="primary" @click="onToastGeneral">Toast General</Button>
    <Button type="primary" @click="onToastSuccess">Toast Success</Button>
    <Button type="primary" @click="onToastError">Toast Error</Button>
    <Button type="primary" :loading="true">Toast Error</Button>
    <Button type="secondary" :loading="true">Toast Error</Button>
    <Button type="subtle" :loading="true">Toast Error</Button>
  </div>
  <div>
    <Button type="primary" @click="onOpenModal">Open modal</Button>
  </div>

  <form>
    <Radio name="radio" label="check" value="1111" />
    <Radio name="radio" label="check" value="2222" />
  </form>

  <Modal :opened="isOpenedModal" @on-close="onCloseModal">
    <span>This is Modal</span>
  </Modal>

  <div>
    <Slider name="slider" :max="500" marker="%" />
  </div>

  <div>
    <InputNumber name="number" />
  </div>
</template>

<style lang="scss"></style>
