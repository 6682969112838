import type { ExtractPropTypes } from 'vue'

export const radioProps = {
  value: {
    type: [String, Number] as PropType<string | number>,
    required: true,
  },
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
} as const

export type TRadioProps = ExtractPropTypes<typeof radioProps>
