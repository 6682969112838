<script lang="ts" setup>
import { radioProps } from './radio'
import { useRadio } from './use-radio'

const id = useId()
const props = defineProps(radioProps)
const { dataValue, onChange } = useRadio(props)
const { value, name, label, disabled } = toRefs(props)
</script>

<template>
  <div class="radio" :class="{ 'radio--disabled': disabled }">
    <input
      type="radio"
      :id="id"
      :name="name"
      v-model="dataValue"
      :value="value"
      :checked="dataValue === value"
    />

    <div class="radio__box" @click="onChange"></div>

    <label v-if="!disabled" :for="id" class="radio__label">{{ label }}</label>
    <span v-else class="radio__label">{{ label }}</span>
  </div>
</template>

<style lang="scss">
.radio {
  display: inline-flex;
  align-items: center;

  input {
    width: 0;
    height: 0;

    opacity: 0;
  }

  input:checked + &__box {
    background: var(--c-primary-default);

    &::after {
      background: var(--c-grayscale-line);
    }
  }

  &__box {
    position: relative;

    width: 32px;
    height: 32px;

    cursor: pointer;
    transition: all 0.3s ease-out;

    border-radius: 50%;
    background: var(--c-grayscale-line);

    &::after {
      position: absolute;
      top: 8px;
      left: 8px;

      width: 16px;
      height: 16px;

      content: '';

      border-radius: 50%;
    }
  }

  &--disabled input:checked + &__box {
    cursor: not-allowed;

    background: var(--c-primary-light);
  }

  &--disabled input + &__box {
    cursor: not-allowed;

    background: var(--c-grayscale-line);
  }

  &__label {
    margin-left: 10px;

    user-select: none;

    color: var(--c-grayscale-placeholder);

    @include t-desktop-text-small;
  }
}
</style>
