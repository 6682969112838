<script lang="ts" setup>
import { sliderProps } from './slider'
import { useSlider } from './use-slider'

const props = defineProps(sliderProps)
const {
  sliderRef,
  sliderButtonRef,
  showTooltip,
  markedValue,
  buttonStyle,
  barStyle,
  onSliderDown,
  handleMouseEnter,
  handleMouseLeave,
  onButtonDown,
} = useSlider(props)
const { marker } = toRefs(props)
</script>

<template>
  <ClientOnly>
    <div ref="sliderRef" class="slider">
      <div
        class="slider__lane"
        @mousedown="onSliderDown"
        @touchstart.passive="onSliderDown"
      >
        <div class="slider__bar" :style="barStyle"></div>

        <button
          ref="sliderButtonRef"
          class="slider__button"
          :style="buttonStyle"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          @mousedown="onButtonDown"
          @focus="handleMouseEnter"
          @blur="handleMouseLeave"
        >
          <div v-if="showTooltip" class="slider__button-tooltip">
            {{ markedValue }}
          </div>
        </button>
      </div>
    </div>
  </ClientOnly>
</template>

<style lang="scss">
.slider {
  display: flex;
  align-items: center;

  width: 100%;
  height: 32px;
  padding: 0 16px;

  &__lane {
    position: relative;

    flex: 1;

    height: 12px;

    cursor: pointer;

    border-radius: 40px;
    background: var(--c-grayscale-line);
  }

  &__bar {
    position: absolute;

    height: 12px;

    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    background: var(--c-primary-default);
  }

  &__button {
    position: absolute;
    top: -10px;

    width: 32px;
    height: 32px;

    transform: translate(-50%);
    text-wrap: nowrap;

    border: 2px solid var(--c-primary-default);
    border-radius: 50%;
    background: var(--c-grayscale-background);

    &:hover {
      cursor: grab;
    }

    &:focus,
    &:focus-within {
      outline: none;
    }

    &-tooltip {
      position: absolute;
      top: -50px;
      left: 50%;

      padding: 6px 16px;

      transform: translate(-50%);

      color: var(--c-primary-dark);
      border-radius: 16px;
      background: var(--c-primary-light);

      @include t-desktop-link-small;

      &::after {
        position: absolute;
        top: 100%;
        left: 50%;

        margin-left: -5px;

        content: ' ';

        border-width: 5px;
        border-style: solid;
        border-color: var(--c-primary-light) transparent transparent transparent;
      }
    }
  }
}
</style>
