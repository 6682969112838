import type { TInputNumberProps } from './input-number'

export const useInputNumber = (props: TInputNumberProps) => {
  const { value: fieldValue, setValue } = useField(props.name, undefined, {
    initialValue: props.min,
  })

  const onDecrease = () => {
    if (props.disabled || fieldValue.value <= props.min) return

    setValue(fieldValue.value - 1)
  }

  const onIncrease = () => {
    if (props.disabled || fieldValue.value >= props.max) return

    setValue(fieldValue.value + 1)
  }

  return {
    fieldValue,
    onDecrease,
    onIncrease,
  }
}
