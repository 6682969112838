import type { TRadioProps } from './radio'

export const useRadio = (props: TRadioProps) => {
  const { value: dataValue, setValue } = useField(props.name, undefined, {
    type: 'radio',
  })

  function onChange() {
    if (props.disabled) return

    setValue(props.value)
  }

  return {
    dataValue,
    onChange,
  }
}
