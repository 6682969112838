import type { ExtractPropTypes } from 'vue'

export const sliderProps = {
  name: {
    type: String,
    default: '',
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  marker: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
} as const

export type TSliderProps = ExtractPropTypes<typeof sliderProps>
